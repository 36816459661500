// libs
import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import classnames from "classnames";

// conponents
import Button from "components/Base/Button";
import { User } from "components/Provider";
import { forbidScroll, allowScroll, isBrowser, toastError } from 'utils/utils'

// api
import { updateUsers } from 'api/user'

// hooks
import useErrorAnimate from "hooks/useErrorAnimate";
// styles
import * as styles from "./index.module.less";

export interface UserInfoProps {
  user_name?: string | null;
  phone?: string | null;
  email?: string | null;
  group_name?: string | null;
  position?: string | null;
};

type UserInfoKeys = keyof UserInfoProps;

interface UserInfoCompleteFormProps {
  className?: string;
  visible?: boolean;
  formTitle: string;
  userInfo?: UserInfoProps;
  onClose?: () => void;
  onSuccess?: (user: User) => void
};

const UserInfoCompleteForm: React.FC<UserInfoCompleteFormProps> = props => {
  const {
    className,
    formTitle,
    userInfo,
    visible,
    onClose,
    onSuccess
  } = props;

  const inlineStyles = {
    UserInfoCompleteForm: classnames(`fixed w-full h-full top-0 left-0 z-[1000] bg-black/40`),
    InputStyle: `
      focus:animate-none 
      border-box
      border-[1px]
      border-solid
      block 
      w-full
      h-[40px]
      rounded-[2px]
      px-[10px]
      py-[12px]
      placeholder:text-[14px]
      placeholder:focus:text-sd-font-input
      placeholder:leading-[16px]
      focus:border-primary`,
    InputErrorStyle: `border-red-500 placeholder:text-red-500`,
    InputErrorAnimate: `animate-headshake `,
    FormItemLabel: `block text-[16px] pb-[10px] text-sd-font-p leading-[1] font-normal`,
  };

  

  const [isClose, setIsClose] = useState<boolean>(!visible);
  const [userInfoFields, setUserInfoFields] = useState<UserInfoProps>(userInfo);
  const [errorFields, setErrorFields] = useState<{ [key: (UserInfoKeys | "privacy")]: boolean }>({});
  const [errorAnimateFields, setErrorAnimateFields] = useErrorAnimate({});
  const [checkedPrivacy, setCheckedPrivacy] = useState<boolean>(false);

  useEffect(() => {
    setIsClose(!visible)
  }, [visible]);

  useEffect(() => {
    if (!isClose) {
      forbidScroll();
    }
    return () => {
      allowScroll();
    }
  }, [isClose]);

  const close = () => {
    allowScroll();
    setIsClose(true);
    onClose && onClose();
  }

  const handleClose: React.EventHandler = (e) => {
    close();
  }

  const handleBlur: (string) => React.EventHandler = (fieldName) => {
    return (e) => {
      let result = false;
      if (!userInfoFields[fieldName]) {
        result = true
      };

      setErrorFields({
        ...errorFields,
        [fieldName]: result
      });
      setErrorAnimateFields(fieldName, result)
    }
  };

  const handleChange: (string) => React.EventHandler = (fieldName) => {
    return (e) => {
      setUserInfoFields({
        ...userInfoFields,
        [fieldName]: e.target.value
      })
    }
  };

  const handleSubmit: React.EventHandler = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const errorFields = userInfoFields
    setErrorFields({
      ...errorFields,
      "user_name": !userInfoFields.user_name,
      "phone": !userInfoFields.phone,
      "email": !userInfoFields.email,
      "group_name": !userInfoFields.group_name,
      "position": !userInfoFields.position,
      "privacy": !checkedPrivacy
    });
    ["user_name", "phone", "email", "group_name", "position"].forEach(fieldName => {
      setErrorAnimateFields(fieldName, !userInfoFields[fieldName]);
    });

    setErrorAnimateFields("privacy", !checkedPrivacy);
    if (userInfoFields.user_name && userInfoFields.phone && userInfoFields.email && userInfoFields.group_name && userInfoFields.position && checkedPrivacy) {
      // 更新用户
      updateUsers({
        userName: userInfoFields.user_name,
        email: userInfoFields.email,
        companyName: userInfoFields.group_name,
        position: userInfoFields.position
      }).then(res => {
        onSuccess({
          user_name: userInfoFields.user_name,
          phone: userInfoFields.phone,
          email: userInfoFields.email,
          group_name: userInfoFields.group_name,
          position: userInfoFields.position
        })
        close();
      }, err => {
        toastError(`更新用户失败 ${err.message}`)
      })
      
    }
  }

  const cnames = classnames(inlineStyles.UserInfoCompleteForm, className, {
    'hidden': isClose
  });
  if (isBrowser()) {
    return ReactDOM.createPortal(
      <div className={cnames}>
        <div className={
          classnames(`fixed w-full h-full bg-black/40 px-[20px]`)
        }>
          <div
            style={{
              transform: 'translateY(-50%)'
            }}
            className="form-wrap bg-sd-light  relative top-1/2 -translate-y-1/2 max-w-[500px] mx-auto">
            <div className="relative form-header text-center py-[20px] border-b-[1px] border-solide border-sd-gap-line">
              <h3 className="text-[18px] leading-[1.5] text-primary font-[500]">{formTitle}</h3>
              <img
                onClick={handleClose}
                src={require("assets/images/icons/icon-close.svg").default}
                alt=""
                className="absolute w-[14px] h-[14px] right-[15px] top-[15px] cursor-pointer" />
            </div>
            {/* form */}
            <form
              className="form-content p-[20px]"
              onSubmit={handleSubmit}>
              <div className="item">
                <label
                  className={
                    classnames(inlineStyles.FormItemLabel, {
                      "text-red-500": errorFields["user_name"]
                    })
                  }
                >姓名*</label>
                <input
                  id="user_name"
                  type="text"
                  className={classnames(inlineStyles.InputStyle, {
                    [`${inlineStyles.InputErrorStyle}`]: errorFields["user_name"],
                    [`${inlineStyles.InputErrorAnimate}`]: errorAnimateFields["user_name"],

                  })}
                  placeholder="请输入您的姓名"
                  defaultValue={userInfo?.user_name}
                  disabled={!!(userInfo?.user_name)}
                  onChange={handleChange("user_name")}
                  onBlur={handleBlur("user_name")}
                />
              </div>

              <div className="item mt-[14px]">
                <label
                  className={
                    classnames(inlineStyles.FormItemLabel, {
                      "text-red-500": errorFields["phone"]
                    })
                  }>手机号*</label>
                <input
                  id="phone"
                  type="text"
                  className={classnames(inlineStyles.InputStyle, {
                    [`${inlineStyles.InputErrorStyle}`]: errorFields["phone"],
                    [`${inlineStyles.InputErrorAnimate}`]: errorAnimateFields["phone"],

                  })}
                  placeholder="请输入您的手机号"
                  defaultValue={userInfo?.phone}
                  disabled={!!(userInfo?.phone)}
                  onChange={handleChange("phone")}
                  onBlur={handleBlur("phone")}
                />
              </div>

              <div className="item mt-[14px]">
                <label
                  className={
                    classnames(inlineStyles.FormItemLabel, {
                      "text-red-500": errorFields["email"]
                    })
                  }>电子邮件*</label>
                <input
                  id="email"
                  type="email"
                  className={classnames(inlineStyles.InputStyle, {
                    [`${inlineStyles.InputErrorStyle}`]: errorFields["email"],
                    [`${inlineStyles.InputErrorAnimate}`]: errorAnimateFields["email"],
                  })}
                  placeholder="请输入您的电子邮件"
                  defaultValue={userInfo?.email}
                  disabled={!!(userInfo?.email)}
                  onChange={handleChange("email")}
                  onBlur={handleBlur("email")}
                />
              </div>

              <div className="item mt-[14px]">
                <label
                  className={
                    classnames(inlineStyles.FormItemLabel, {
                      "text-red-500": errorFields["group_name"]
                    })
                  }>公司名称*</label>
                <input
                  id="group_name"
                  type="text"
                  className={classnames(inlineStyles.InputStyle, {
                    [`${inlineStyles.InputErrorStyle}`]: errorFields["group_name"],
                    [`${inlineStyles.InputErrorAnimate}`]: errorAnimateFields["group_name"],
                  })}
                  placeholder="请输入您的公司名称"
                  defaultValue={userInfo?.group_name}
                  disabled={!!(userInfo?.group_name)}
                  onChange={handleChange("group_name")}
                  onBlur={handleBlur("group_name")}
                />
              </div>

              <div className="item mt-[14px]">
                <label className={
                  classnames(inlineStyles.FormItemLabel, {
                    "text-red-500": errorFields["position"]
                  })
                }>职务*</label>
                <input
                  id="position"
                  type="text"
                  className={classnames(inlineStyles.InputStyle, {
                    [`${inlineStyles.InputErrorStyle}`]: errorFields["position"],
                    [`${inlineStyles.InputErrorAnimate}`]: errorAnimateFields["position"],

                  })}
                  placeholder="请输入您的职务"
                  defaultValue={userInfo?.position}
                  disabled={!!(userInfo?.position)}
                  onChange={handleChange("position")}
                  onBlur={handleBlur("position")}
                />
              </div>

              <div className={classnames(`privacy relative mt-[10px] text-[14px] text-sd-font-input leading-[2]`, {
                'animate-headshake': errorAnimateFields['privacy'],
              })}>
                <input
                  value={checkedPrivacy}
                  onChange={e => {
                    setCheckedPrivacy(e.target.checked)
                  }}

                  onBlur={e => {
                    setErrorFields({
                      ...errorFields,
                      "privacy": !e.target.checked
                    });
                    setErrorAnimateFields('privacy', !e.target.checked);
                  }}
                  type="checkbox"
                  id="privacy"
                  className="peer relative z-10 opacity-0 align-middle cursor-pointer" />

                <span className={classnames(
                `
                    absolute
                    align-middle
                    border-[1px]
                    rounded-[50%]
                    border-box
                    w-[14px]
                    h-[14px]
                    left-0
                    top-[7px]
                    cursor-pointer
                    peer-checked:bg-primary
                    peer-checked:border-primary
                    peer-checked:before:absolute
                    peer-checked:before:content
                    peer-checked:before:block
                    peer-checked:before:bg-sd-light
                    peer-checked:before:h-[1px]
                    peer-checked:before:w-[8px]
                    peer-checked:before:top-[6px]
                    peer-checked:before:left-[3px]
                    peer-checked:before:rotate-[-45deg]
                    peer-checked:after:content
                    peer-checked:after:absolute
                    peer-checked:after:block
                    peer-checked:after:bg-sd-light
                    peer-checked:after:h-[1px]
                    peer-checked:after:w-[5px]
                    peer-checked:after:top-[7px]
                    peer-checked:after:left-0
                    peer-checked:after:rotate-[45deg]
              `, {
                    'border-red-500': errorFields['privacy'],
                    'border-sd-grey': !errorFields['privacy']
                }
                )}></span>

                <span className="ml-[10px]">我提交的详细信息并同意 <a className="text-primary" href="https://www.sensorsdata.cn/compliance/privacy.html" target="_blank" >《神策隐私政策》</a> | <a className="text-primary" href="https://www.sensorsdata.cn/compliance/service.html" target="_blank">
                  《用户服务协议》</a></span>
              </div>

              <div className="text-center ">
                <input
                  type="submit"
                  className="bg-primary text-sd-light mt-[10px]  text-[16px] leading-[1] px-[34px] py-[12px] rounded-[2px] cursor-pointer"
                  value="提交" />
              </div>
            </form>
          </div>
        </div>
      </div>, document.body);
  } else {
    return null;
  }
  
};


export default UserInfoCompleteForm;
