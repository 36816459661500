// libs
import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import classnames from 'classnames'
import { ToastContainer, toast } from 'react-toastify'
import { NumberParam, StringParam, useQueryParam } from 'use-query-params'
import { navigate } from 'gatsby'
import { number, string } from 'prop-types'

import Layout from 'layouts/zh'

// components
import Seo from 'components/seo'
import PageBottomCard from 'components/PageBottomCard'
import SideMenu, { MenuDataProp } from 'components/TargetDisassembly/SideMenu'
import ContactCard from 'components/TargetDisassembly/ContactCard'
import TargetDetail from 'components/TargetDisassembly/TargetDetail'
import SearchBanner, { SearchBannerTypes } from 'components/TargetDisassembly/SearchBanner'
import { providerContext } from 'components/Provider'
import UserInfoCompleteForm from 'components/TargetDisassembly/UserInfoCompleteForm'

// utils
import { Languages } from 'i18n/config'
import { forbidScroll, allowScroll, toastError } from 'utils/utils'
import { _cookie } from 'utils/statistics'

// apis
import { fetchWhoami, fetchWhoamiI18n } from 'api/user'
import { fetchAllIndustry, fetchTarget, searchTarget } from 'api/doc'
import { getHotTagList } from 'api'

// styles
import 'react-toastify/dist/ReactToastify.css'
import 'styles/base.less'
import * as styles from './index.module.less'
import '@eefe/sensors-login/dist/sensors-login.css'

const Target: React.FC = (props) => {
  return (
    <Layout footerClassName="hidden lg:block" hideSmallPoint={true} {...props}>
      <TargetState />
    </Layout>
  )
}

interface TargetStateProps {
  targetId: string
  lang?: Languages
}

interface TargetProps {
  title: string
  fileUrl: string
  fileName: string
  content: string
}

let SensorsLogin: any

export const TargetState: React.FC<TargetStateProps> = (props) => {
  const { lang } = props
  const [targetId] = useQueryParam('id', StringParam)
  const isSatitleInit = useRef<boolean>(false)
  let { user, setUser } = useContext(providerContext)
  const maskRef = useRef<HTMLDivElement>(null)
  const saTitle = useRef<string>('指标拆解工具｜数据指标体系查询平台-神策数据')
  const [menuData, setMenuData] = useState<Array<MenuDataProp>>([])
  const [target, setTarget] = useState<TargetProps | null>(null)
  const [openKey, setOpenKey] = useState<string | null>(null)
  const [hotTags, setHotTags] = useState<
    Array<{
      tagName: string
      tagId: string
    }>
  >([])
  const [loginFormOpen, setLoginFormOpen] = useState<boolean>(false)
  useEffect(async () => {
    if (!SensorsLogin) {
      SensorsLogin = require('@eefe/sensors-login/dist/sensors-login.js')
    }
  }, [])
  // 判断是否要显示强制登录界面
  useEffect(async () => {
    if (isSatitleInit.current) {
      try {
        const res = await fetchWhoami()
        const { data, status } = res.data
        if (status === 200) {
          // 已登录
        } else {
          // 未登录
          openLoginDialgo()
        }
      } catch (err) {
        toastError(err.message)
      }
    }
  }, [targetId, isSatitleInit.current])

  useEffect(() => {
    // 获取menu数据
    fetchAllIndustry()
      .then(({ data }) => {
        const menuData = data.map((obj) => {
          const menuItems = obj.documents.map((document) => {
            return {
              key: document.objId,
              name: document.name,
            }
          })
          return {
            key: obj.objId,
            name: obj.tagName,
            items: menuItems,
          }
        })
        setMenuData(menuData)
      })
      .catch((err) => {
        toastError(err.message)
      })

    getHotTagList()
      .then(({ data }) => {
        setHotTags(
          data.map((obj) => {
            return {
              tagName: obj.targetName,
              tagId: obj.targetId,
            }
          }),
        )
      })
      .catch((err) => {
        toastError(err.message)
      })

    return () => {
      allowScroll()
    }
  }, [targetId])

  useEffect(() => {
    // 获取指标详情
    if (targetId) {
      fetchTarget(targetId)
        .then(({ data }) => {
          saTitle.current = `${data?.name}-${data?.tagName} | 数据指标体系查询平台-神策数据`
          isSatitleInit.current = true
          // sa?.registerPage({
          //   $title: saTitle.current,
          // })

          setTarget({
            title: data.name,
            fileUrl: data.docAttUrl,
            fileName: data.docAttName,
            content: data.content,
          })
        })
        .catch((err) => {
          toastError(err.message)
        })
    }
  }, [targetId])

  // 关闭登录浮框
  const closeLoginDialgo = () => {
    setLoginFormOpen(false)
  }

  // const handleLoginSuccess = async () => {
  //   const boxWarp = document.getElementById('sensors-login')
  //   const domMask = document.createElement('img')
  //   domMask.className = 'absolute  z-[99]    top-[112px]  w-[380px]  h-[274px] left-1/2 translate-x-[-50%]'
  //   domMask.src = require('assets/images/target_disassembly/sensors-login-logo.svg').default
  //   boxWarp?.appendChild(domMask)
  // }

  // 打开登录浮框
  const openLoginDialgo = () => {
    // 从cookie中拿forward_first_title属性传给后端
    // let _uq_ = JSON.parse(_cookie.getItem('_uq_') || '[]')
    // let forward_first_title = ''
    // if (_uq_.length > 1) {
    //   forward_first_title = _uq_?.[1]
    // }
    if (!loginFormOpen) {
      setLoginFormOpen(true)
      const domWrap = document.createElement('div')
      domWrap.setAttribute('id', 'sensors-login-wrap')
      domWrap.className = 'fixed z-[1000] lg:z-0 top-0 left-0 lg:absolute w-full h-full'
      domWrap.style.backgroundColor = 'rgba(0,0,0,.5)'
      const contentWrap = document.createElement('div')
      contentWrap.classList = 'relative h-full w-full pt-[20vh] pb-[50px] lg:py-0 overflow-y-scroll'
      const dom = document.createElement('div')
      dom.setAttribute('id', 'sensors-login')
      dom.className = classnames(`relative lg:top-1/2  lg:left-1/2 mx-[10px] lg:mx-auto `, styles.LoginCard)
      // 移动端阻止滚动穿透
      let targetLeftY = 0
      domWrap.addEventListener('touchstart', function (e) {
        targetLeftY = Math.floor(e.targetTouches[0].clientY)
      })

      domWrap.addEventListener('touchmove', function (e) {
        let newTargetY = Math.floor(e.targetTouches[0].clientY)
        let sT = contentWrap.scrollTop
        let sH = contentWrap.scrollHeight
        let cH = contentWrap.clientHeight
        if (sT <= 0 && newTargetY - targetLeftY > 0) {
          e.preventDefault()
        } else if (sT >= sH - cH && newTargetY - targetLeftY < 0) {
          e.preventDefault()
        }
      })
      // 未登录
      SensorsLogin?.renderLogin(
        {
          lang: '', // 语言环境，默认中文简体：zh-CN，繁体：zh-TW
          domainPassword: process.env.API_URL, // 登录接口域名配置，可不传，默认：/api/passport，测试环境可配置：/api/_passport
          // domainSchool: 'https://school.sensorsdata.cn',  // 学堂接口域名配置，可不传，默认：https://school.sensorsdata.cn
          config: {
            channel: 'target_tools', // 渠道字段，
            requestBtn: '', // 按钮名
            formpage_source: '', // 注册表单类型，可选值：视频、demo、文档、预约、白皮书
            document_name: '', // 注册文档名称
            video_name: '', // 注册视频名称
            forward_first_title: '指标拆解工具', //前向页面字段
          },
          // handleAutoLogin: () => {
          //   handleLoginSuccess()
          // }, //老用户扫码成功后回调
          onLoginSuccess: function () {
            domWrap.remove()
            closeLoginDialgo()
            window.location.reload()
          }, // 登录成功回调事件
        },
        dom,
      )
      contentWrap.appendChild(dom)
      domWrap.appendChild(contentWrap)
      maskRef.current.appendChild(domWrap)

      if (maskRef.current) {
        let preHeight = 0
        let observer = new MutationObserver(function (mutations, observer) {
          const height = dom.getBoundingClientRect().height + 80 * 2
          if (maskRef.current) {
            if (preHeight !== height) {
              preHeight = height
              maskRef.current.style.minHeight = `${height}px`
              maskRef.current.style.maxHeight = `${height}px`
            }
          }
        })
        observer.observe(dom, {
          childList: true, // 子节点的变动（新增、删除或者更改）
          attributes: true, // 属性的变动
          characterData: true, // 节点内容或节点文本的变动
          subtree: true, // 是否将观察器应用于该节点的所有后代节点
        })
      }
    }
  }

  const findParentKey = (
    source: Array<MenuDataProp>,
    key: string,
  ): {
    key: string
    index: number
  } => {
    for (let i = 0; i < source.length; i++) {
      const menuData = source[i]
      if (menuData.items) {
        for (let j = 0; j < menuData.items.length; j++) {
          const item = menuData.items[j]
          if (item.key === key) {
            return {
              key: menuData.key,
              index: j,
            }
          }
        }
      }
    }
    return {
      key: null,
      index: null,
    }
  }

  const openKeys = useMemo(() => {
    return [findParentKey(menuData, targetId).key]
  }, [menuData, targetId])

  const itemIndex = useMemo(() => {
    return [findParentKey(menuData, targetId).index]
  }, [menuData, targetId])

  const blurStyle = {
    'blur-lg': user == null,
  }

  const inlineStyles = {
    Banner: ``,
    Target: `flex flex-row mx-[20px] justify-center lg:mx-[120px] lg:pb-[100px] mt-[30px] lg:min-h-[564px]`,
    SideMenu: `w-[196px] hidden lg:block flex-shrink-0 bg-sd-light`,
    MaskArea: `relative lg:ml-[28px] xl:ml-[48px] mb-[100px] lg:mb-0 flex-grow lg:flex-grow-0 flex flex-col lg:flex-row`,
    TargetContentWrap: `flex-grow lg:flex-grow-0 lg:w-[724px] lg:mr-[28px] xl:mr-[48px] overflow-hidden shadow-[0_0_20px_0_rgba(153,169,191,0.2)] bg-sd-light`,
    TargetCongtent: `px-[20px] lg:px-[30px] py-[20px] bg-sd-light`,
    AsideRight: `mt-[14px] lg:mt-0 w-full lg:w-[190px] flex-shrink-0 lg:h-[250px] shadow-[0_0_20px_0_rgba(153,169,191,0.2)] bg-sd-light `,
    FullFileWrap: `lg:hidden bg-primary h-[60px] fixed bottom-0 w-full z-[999]`,
    FullFile: `flex justify-center items-center text-center text-sd-light text-[20px] leading-[1.5] h-full`,
  }

  return (
    <>
      <Seo
        title={saTitle?.current}
        description="神策数据指标拆解工具能够提供各行业数据指标查询，帮助企业合理构建指标体系，从而使分析师、市场营销、产品、运营等人员能够及时通过关注各项指标进行数据分析，调整营销策略，从而为公司业务带来最大价值。"
        keywords="指标体系,数据指标,指标体系构建,运营数据指标,电商数据指标"
        saTitle={saTitle?.current}
        isSatitleInit={isSatitleInit.current}
      />
      <div className="bg-sd-banner-bg">
        <header className={classnames(inlineStyles.Banner)}>
          <SearchBanner
            className="!pt-20 lg:!pt-[70px]"
            type={SearchBannerTypes.slim}
            onSearch={(key) => {
              return new Promise((resolve, reject) => {
                setTimeout(() => {
                  if (key.length > 0) {
                    searchTarget(key, 1, 100)
                      .then((res) => {
                        const { data } = res.data
                        resolve(
                          data.map((obj) => {
                            return {
                              name: obj.name,
                              key: obj.objId,
                              cate: obj.tagName,
                            }
                          }),
                        )
                      })
                      .catch((err) => reject(err))
                  } else {
                    resolve([])
                  }
                }, 0)
              })
            }}
            tags={hotTags}
          />
        </header>
        <main
          className={classnames(styles.Target, inlineStyles.Target, {
            // "lg:h-[564px]": user == null
          })}
        >
          <SideMenu
            className={inlineStyles.SideMenu}
            menuDatas={menuData}
            onItemSelected={(key) => {
              navigate(`/target_disassembly/target.html?id=${encodeURIComponent(key)}`)
            }}
            openKeys={openKeys}
            selectedKeys={[targetId]}
            itemIndex={itemIndex}
          />

          <section id="mask" ref={maskRef} className={inlineStyles.MaskArea}>
            <section
              style={{
                boxShadow: '0 0 20px 0 rgba(153, 169, 191, 20%)',
              }}
              className={classnames(inlineStyles.TargetContentWrap, blurStyle)}
            >
              <TargetDetail
                className={inlineStyles.TargetCongtent}
                title={target && target.title}
                content={target && target.content}
                fileName={target && target.fileName}
                fileUrl={target && target.fileUrl}
              />
            </section>

            <div className={classnames(inlineStyles.AsideRight, blurStyle)}>
              <ContactCard
                title="了解更多指标工具"
                qrCode={require('assets/images/target_disassembly/wechat.png').default}
                desc="扫码添加咨询顾问"
              />
            </div>
          </section>
        </main>
      </div>

      <ToastContainer />
    </>
  )
}

export default Target
