import React from 'react'
import classnames from 'classnames'
import SearchInput, { SearchResultProp } from '../SearchInput'
import { navigate } from 'gatsby'

export enum SearchBannerTypes {
  wide = 'wide',
  slim = 'slim',
}

interface SearchBannerProps {
  type?: SearchBannerProps
  className?: string
  tags?: Array<string>
  onSearch?: (string) => Promise<Array<SearchResultProp>>
}

const SearchBanner: React.FC<SearchBannerProps> = (props) => {
  const { className, type = SearchBannerTypes.wide, tags = [], onSearch } = props

  const handleClickHotTag = (id) => (e) => {
    navigate(`/target_disassembly/target.html?id=${encodeURIComponent(id)}`)
  }

  const styles = {
    slim: {
      searchBanner: `
        flex
        flex-col
        relative
        lg:flex-row
        justify-center
        items-stretch
        lg:items-start
        xl:px-[240px]
        lg:px-[120px]
        bg-gradient-to-b from-[#0BDE9B] to-[#14C19B]
        bg-sd-green-deep-deep
        bg-no-repeat
        bg-[length:100%_100%]
        lg:h-[150px]
        pt-[22px]
        lg:pt-[20px]
      `,
      searchWrap: `lg:ml-[20px] flex-grow-0 pb-[20px] w-[335px] lg:w-[800px] mx-auto lg:mx-0`,
      searchIcon: `w-[24px] h-[24px]`,
      titleText: `lg:relative lg:top-[10px] text-[24px] lg:text-[36px] leading-normal lg:leading-[60px] text-sd-light text-center whitespace-nowrap`,
      searchBox: `flex flex-row h-[50px] w-full px-[20px] mb-[22px]`,
    },
    wide: {
      searchBanner: `
          flex
          flex-col
          relative
          justify-center
          items-stretch
          pt-[22px]
          lg:pt-[34px]
          bg-gradient-to-b from-[#0BDE9B] to-[#14C19B]
          bg-sd-green-deep-deep
          bg-no-repeat
          bg-[length:100%_100%]
          lg:h-[230px]
      `,
      searchWrap: `relative pb-[12px] lg:pb-[34px] w-[335px] lg:w-[800px] mx-auto`,
      searchIcon: `w-[24px] h-[24px]`,
      titleText: `relative text-[24px] lg:text-[36px] leading-normal text-sd-light text-center`,
      searchBox: `flex flex-row  h-[50px] w-full px-[20px] mb-[22px]`,
    },

    tags: `
      min-h-[28px]
      my-[10px]
      hidden 
      lg:block 
      text-sd-light/70
      text-[14px] 
      leading-[2]
      text-sd-tags
    `,
    tag: `
      relative
      hover:text-sd-light 
      py-[5px]
      after:content
      after:absolute 
      after:bottom-0 
      after:left-0 
      after:h-[1px] 
      after:w-full 
      after:scale-x-0 
      hover:after:scale-x-[1] 
      after:bg-sd-light 
      after:transition-all
      cursor-pointer
    `,
  }
  return (
    <div className={className}>
      <div className={classnames(styles[type].searchBanner)}>
        {type === SearchBannerTypes.wide && (
          <>
            <img
              src={require('assets/images/target_disassembly/bg_pc.webp').default}
              alt=""
              style={{
                transform: 'translateX(-50%)',
              }}
              className={`
                  absolute 
                  w-[480px] 
                  lg:w-[680px] 
                  left-1/2 
                  -translate-x-1/2 
                  bottom-0 
                  hidden 
                  md:block
                `}
            />
            <img
              src={require('assets/images/target_disassembly/bg_mobile.webp').default}
              alt=""
              style={{
                transform: 'translateX(-50%)',
              }}
              className={`
                  absolute 
                  w-[373px] 
                  left-1/2 
                  -translate-x-1/2 
                  bottom-0
                  md:hidden
                `}
            />
          </>
        )}
        <h1
          onClick={(e) => navigate(`/target_disassembly.html`)}
          className={classnames(styles[type].titleText, `cursor-pointer`)}
        >
          指标拆解工具
        </h1>
        <div className={classnames(styles[type].searchWrap)}>
          <SearchInput
            className="my-[10px]"
            defaultPlaceholder="请输入查询内容"
            onSearch={(key, setSearchResults, setShowResult) => {
              onSearch &&
                onSearch(key)
                  .then((data) => {
                    setSearchResults(data)
                    if (key) {
                      setShowResult(true)
                    } else {
                      setShowResult(false)
                    }
                  })
                  .catch((err) => {
                    console.log(err)
                  })
            }}
          />

          <div className={classnames(styles.tags)}>
            {' '}
            <span className="pr-[5px]">热门指标:</span>{' '}
            {tags.map((v, index) => {
              if (index === tags.length - 1) {
                return (
                  <a className={styles.tag} key={v.tagId} onClick={handleClickHotTag(v.tagId)}>
                    {v.tagName}
                  </a>
                )
              } else {
                return (
                  <a
                    className={classnames(styles.tag, 'after:w-[88%]')}
                    key={v.tagId}
                    onClick={handleClickHotTag(v.tagId)}
                  >
                    {v.tagName}，
                  </a>
                )
              }
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchBanner
