import React, { memo, useEffect, useState } from 'react'
import classnames from 'classnames'
import Menu, { SubMenu, MenuItem, MenuItemProps, MenuRef } from 'rc-menu'
import Tooltip from 'rc-tooltip'

import * as styles from './index.module.less'
import './style.css'
import 'rc-tooltip/assets/bootstrap.css'
import { useRef } from 'react'

function MenuItemWithTooltip(props: MenuItemProps): React.ReactElement {
  return (
    <Tooltip overlay={<span>{props.children}</span>} trigger="hover" placement="right">
      <MenuItem {...props}>{props.children}</MenuItem>
    </Tooltip>
  )
}

export interface MenuItemDataProp {
  key: string
  name: string
}

export interface MenuDataProp {
  key: string
  name: string
  items?: Array<MenuItemDataProp>
}

interface MenuProps {
  menuDatas?: Array<MenuDataProp>
  className?: string
  style: string
  openKeys?: Array<string>
  selectedKeys?: Array<string>
  itemIndex: number
  onItemSelected?: (key) => void
}

const SideMenu: React.FC<MenuProps> = (props) => {
  const { menuDatas = [], className, style, openKeys = [], selectedKeys = [], itemIndex, onItemSelected } = props

  // 如果菜单数据为空，直接返回null。避免做无用计算
  if (menuDatas.length == 0) {
    return null
  }

  const [menuMetaData, setMenuMetaData] = useState<{
    openKeys: Array<string>
    selectedKeys: Array<string>
  }>({
    openKeys,
    selectedKeys,
  })
  const itemRef = useRef<MenuRef>(null)

  useEffect(() => {
    // 菜单跟随保证所需菜单项始终在用户视觉中
    if (itemRef.current) {
      let listRef = itemRef.current.list ? itemRef.current.list : itemRef.current
      listRef.childNodes.forEach((childNode: ChildNode) => {
        const classList: DOMTokenList = childNode.classList
        if (classList.contains('rc-menu-submenu-open')) {
          if (childNode.lastChild?.nodeName === 'UL') {
            const selectedItemPosition = itemIndex * 28
            const currentScrollHeight = childNode.lastChild.scrollTop
            if (selectedItemPosition - currentScrollHeight > 360 || currentScrollHeight > selectedItemPosition) {
              childNode.lastChild.scrollTop = (itemIndex - 4) * 28
            }
          }
        }
      })
    }
  }, [itemIndex])

  useEffect(() => {
    setMenuMetaData({
      openKeys,
      selectedKeys,
    })
  }, [openKeys, selectedKeys])

  const cnames = classnames(styles.Menu, className, `!pl-[20px] !pr-[20px] overflow-scroll`)
  const expandIcon = (
    <i
      style={{
        transform: `rotate(0deg)`,
      }}
      className={`inline-block ml-[10px] align-middle transition-transform expand-icon`}
    >
      <svg
        t="1647680255177"
        className="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="2622"
        width="1em"
        height="1em"
        fill="currentColor"
      >
        <path
          d="M320 938.7c-10.9 0-21.8-4.2-30.2-12.5-16.7-16.7-16.7-43.7 0-60.3L643.7 512 289.8 158.2c-16.7-16.7-16.7-43.7 0-60.3s43.7-16.7 60.3 0l384 384c16.7 16.7 16.7 43.7 0 60.3l-384 384c-8.3 8.3-19.2 12.5-30.1 12.5z"
          p-id="2623"
        ></path>
      </svg>
    </i>
  )

  if (menuDatas.length > 0) {
    return (
      <Menu
        ref={itemRef}
        className={cnames}
        mode="inline"
        style={style}
        openKeys={menuMetaData.openKeys}
        selectedKeys={menuMetaData.selectedKeys}
        onClick={({ key }) => {
          onItemSelected && onItemSelected(key)
        }}
        onSelect={({ selectedKeys }) =>
          setMenuMetaData({
            ...menuMetaData,
            selectedKeys,
          })
        }
        onOpenChange={(openKeys) => {
          const latestOpenKey = openKeys.find((key) => menuMetaData.openKeys.indexOf(key) === -1)
          setMenuMetaData({
            ...menuMetaData,
            openKeys: latestOpenKey ? [latestOpenKey] : [],
          })
        }}
        motion={{
          motionName: 'rc-menu-open-slide-up',
          motionAppear: true,
          motionEnter: true,
          motionLeave: true,
        }}
      >
        {menuDatas.map((menuData) => (
          <SubMenu expandIcon={expandIcon} key={menuData.key} title={menuData.name}>
            {menuData.items &&
              menuData.items.map((itemData) => (
                <MenuItemWithTooltip className="side-menu-item" key={itemData.key}>
                  {itemData.name}
                </MenuItemWithTooltip>
              ))}
          </SubMenu>
        ))}
      </Menu>
    )
  } else {
    return <div className={cnames}></div>
  }
}

export default SideMenu
