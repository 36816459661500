import { useReducer } from "react";

type ACTIONTYPE = { type: string; payload: boolean };
type STATE_TYPE = {
  [fieldName: string]: boolean
};
function reducer(state: STATE_TYPE, action: ACTIONTYPE) {
  return {
    ...state,
    [action.type]: action.payload,
  };
}

function useErrorAnimate(initState: STATE_TYPE) {
  const [state, dispatch] = useReducer(reducer, initState);
  return [
    state,
    (key, value) => {
      dispatch({
        type: key,
        payload: value,
      });
      if (value) {
        setTimeout(() => {
          dispatch({
            type: key,
            payload: false,
          });
        }, 1000);
      }
    },
  ];
}

export default useErrorAnimate;