import React, { useContext, useEffect, useRef, useState, useMemo } from 'react'
import classnames from 'classnames'

import { providerContext } from 'components/Provider'
import UserInfoCompleteForm from '../UserInfoCompleteForm'

// styles
import * as styles from './index.module.less'

// apis
import { downloadFile } from 'api/file'

interface TargetDetailProps {
  className?: string
  title: string
  content: string
  fileName: string
  fileUrl: string
}
const TargetDetail: React.FC<TargetDetailProps> = (props) => {
  const { className, title, content, fileName, fileUrl } = props

  const inlineStyles = {
    Title: `text-[20px] lg:text-[24px] leading-[1.5] text-primary font-[500] lg:mb-[10px] `,
    Content: `text-[14px] leading-[2] primary-sd-black lg:mt-[10px]`,
    FullFileWrap: `lg:hidden bg-primary h-[60px] fixed bottom-0 left-0 w-full z-[665]`,
    FullFile: `flex justify-center items-center text-center text-sd-light text-[20px] leading-[1.5] h-full`,
  }

  let { user, setUser } = useContext(providerContext)
  const [showInfoCompleteForm, setShowInfoCompleteForm] = useState<boolean>(false)
  const contentRef = useRef<HTMLDivElement | null>(null)
  // 小程序模式初始化
  const miniprogramFlagRef = useRef<boolean>(false)
  const userInfCompleted = useMemo(() => {
    return user && (!user.user_name || !user.phone || !user.email || !user.position || !user.group_name)
  }, [user])

  // 使用axios下载文件 可以保证文件名可读性
  const downloadFileFromRemote = (fileUrl, fileName) => {
    downloadFile(fileUrl).then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: 'application/ocete-stream',
          'Content-Disposition': 'attachment',
        }),
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('target', '__blank')
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
    })
  }

  const openFile = (fileUrl) => {
    if (miniprogramFlagRef.current && /android/i.test(navigator.userAgent)) {
      // 安卓手机微信条件下
      var pdfurl = fileUrl
      if (/^\/\/\w+/.exec(pdfurl)) {
        pdfurl = 'https:' + pdfurl
      }
      wx.miniProgram.navigateTo({
        url: '/pages/pdf/pdf?pdf=' + pdfurl,
        success: function (res) {
          // success
        },
        fail: function () {
          // fail
        },
        complete: function () {
          // complete
        },
      })
    } else {
      const link = document.createElement('a')
      link.href = fileUrl
      link.setAttribute('target', '__blank')
      document.body.appendChild(link)
      link.click()
    }
  }

  const handleOpenFullFile = (e) => {
    if (user) {
      if (userInfCompleted) {
        setShowInfoCompleteForm(true)
      } else {
        downloadFileFromRemote(fileUrl, fileName)
      }
    }
  }

  const handleOpenFullFileForMobile = (e) => {
    if (user) {
      if (userInfCompleted) {
        setShowInfoCompleteForm(true)
      } else {
        openFile(fileUrl)
      }
    }
  }
  // 判断是微信环境还是其他
  const browserRedirect = () => {
    const sUserAgent = navigator.userAgent.toLowerCase()
    const wx = sUserAgent.match(/MicroMessenger/i) == 'micromessenger'
    if (wx) {
      return 'wx'
    } else {
      return 'others'
    }
  }
  const miniprogram = (miniFunc, wxMobileFunc) => {
    try {
      wx?.miniProgram.getEnv(function (res) {
        // wx.miniProgram.getEnv 只支持在 1.7.1 版本以上的小程序的webview中使用。所以下面要做个兼容。
        if (res.miniprogram) {
          // miniprogramFlag = true;
          miniprogramFlagRef.current = true
          miniFunc && miniFunc()
        } else {
          //在微信中
          wxMobileFunc && wxMobileFunc()
        }
      })
    } catch (error) {
      if (window?.__wxjs_environment === 'miniprogram') {
        // miniprogramFlag = true;
        miniprogramFlagRef.current = true
        miniFunc && miniFunc()
      } else {
        // miniprogramFlag = false;
        miniprogramFlagRef.current = false
        wxMobileFunc && wxMobileFunc()
      }
    }
  }
  useEffect(() => {
    // 给video包裹一个video-wrap，使其能保证安装16:9的比例显示视频
    if (contentRef.current) {
      contentRef.current.childNodes.forEach((childNode) => {
        if (childNode.nodeName == 'VIDEO') {
          const wrap = document.createElement('div')
          wrap.className = 'video-wrap'
          contentRef.current.replaceChild(wrap, childNode)
          wrap.appendChild(childNode)
        }
      })

      // 删除内容中最后一个<p><br/></p> 这个是后台quill默认换行tag,只能在前端这边使用dom api把最后一个换行符去除
      const lastChild = contentRef.current.lastChild
      if (lastChild) {
        if (lastChild.nodeName == 'P' && lastChild.firstChild?.nodeName == 'BR') {
          contentRef.current.removeChild(lastChild)
        }
      }
    }
  }, [content])
  useEffect(() => {
    browserRedirect() === 'wx' && miniprogram()
  }, [])

  const cnames = classnames(styles.TargetDetail, className)
  return (
    <div className={cnames}>
      <h3 className={inlineStyles.Title}>{title}</h3>
      {fileUrl && (
        <p
          className={`
          bg-sd-tool-bg
          text-[14px]
          leading-[2]
          text-sd-font-input
          pl-[10px]
          hidden
          lg:block
        `}
        >
          点击免费下载指标体系，更多解读内容可在屏幕右侧扫码获取。
          <a
            className={`
          text-primary
            underline
            py-[2px]
            cursor-pointer
          `}
            onClick={handleOpenFullFile}
          >
            {fileName}
          </a>
        </p>
      )}
      <div className={classnames(inlineStyles.Content, styles.Content)}>
        {<article dangerouslySetInnerHTML={{ __html: content }} ref={contentRef}></article>}
      </div>

      {fileUrl && (
        <div className={inlineStyles.FullFileWrap}>
          <p onClick={handleOpenFullFileForMobile} className={inlineStyles.FullFile} data-file={fileUrl}>
            查看指标体系
          </p>
        </div>
      )}

      {/* 完善个人信息模态框 */}
      {user && (
        <UserInfoCompleteForm
          formTitle="完善个人信息"
          userInfo={{
            user_name: user.user_name,
            phone: user.phone,
            email: user.email,
            group_name: user.group_name,
            position: user.position,
          }}
          visible={showInfoCompleteForm}
          onClose={() => setShowInfoCompleteForm(false)}
          onSuccess={({ user_name, phone, email, group_name, position }) => {
            setUser({
              ...user,
              user_name,
              phone,
              email,
              group_name,
              position,
            })
          }}
        />
      )}
    </div>
  )
}

export default TargetDetail
