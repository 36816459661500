import React from "react";
import classnames from "classnames";

import * as styles from "./index.module.less";

interface ContactCardProps {
  className?: string;
  title: string;
  qrCode: string;
  desc: string;
}
const ContactCard: React.FC<ContactCardProps> = props => {
  const {
    className,
    title,
    qrCode,
    desc
  } = props;

  const inlineStyles = {
    ContactCard: `px-[20px]`,
    Title: `text-[18px] text-sd-black leading-[27px] font-normal mt-[14px] pb-[10px] border-b-[1px] border-sd-gap-line `,
    QrCode: `relative text-center mt-[28px] mb-[4px] p-[7px] mx-auto w-[132px]`,
    QrCodeImg: `p-[6px] border border-sd-gap-line border-solid`,
    BorderStyle: `absolute
      w-[13px]
      h-[13px]
      box-border
      border-2`,
    Desc: `text-[14px] text-primary leading-[2] font-normal text-center mb-[9px]`
  }

  const cnames = classnames(styles.ContactCard, inlineStyles.ContactCard,className);
  return (
    <div className={cnames}>
      <h3 className={classnames(inlineStyles.Title)}>{title}</h3>
      <div className={classnames(inlineStyles.QrCode)}>
        <div className={classnames(inlineStyles.BorderStyle, `
          top-0
          left-0
          border-primary
          border-r-transparent
          border-b-transparent
        `)}></div>
        <div className={classnames(inlineStyles.BorderStyle, `
          bottom-0
          right-0
          border-primary
          border-t-transparent
          border-l-transparent
        `)}></div>
        <div className={classnames(inlineStyles.BorderStyle, `
          top-0
          right-0
          border-primary
          border-l-transparent
          border-b-transparent
        `)}></div>
        <div className={classnames(inlineStyles.BorderStyle, `
          bottom-0
          left-0
          border-primary
          border-t-transparent
          border-r-transparent
        `)}></div>
        <img
          className={inlineStyles.QrCodeImg}
          src={qrCode}/>
      </div>
      <p className={classnames(inlineStyles.Desc)}>{desc}</p>
    </div>
  );
};


export default ContactCard;
